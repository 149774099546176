import React, { useMemo } from "react";
import Head from "../../components/Head";
import styled, { createGlobalStyle } from "styled-components";
import Context from "../../components/Context";

const Global = createGlobalStyle`
  html,
  body {
    margin: 0;
    padding: 0;
    font-family: "Inter UI", sans-serif;
    height: 100%;
    background-color: #004358;
    color: #fff;
    overflow-y: hidden;
  }

  #app * {
    box-sizing: border-box;
  }
`;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 16px;
  display: grid;
  grid-template:
    "header header"
    "column body" 1fr / 1fr 4fr;

  @media (max-width: ${({ theme }) => theme.verticalLayout}px) {
    grid-template:
      "header header"
      "column column"
      "body body" 1fr / 1fr;
  }
`;

const LeftColumn = styled.div`
  grid-area: column;
  border-right: 1px solid rgba(255, 255, 255, 0.6);
  @media (max-width: ${({ theme }) => theme.verticalLayout}px) {
    border-right: none;
  }
`;

const Header = styled.div`
  grid-area: header;
  padding: 16px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
`;

const Body = styled.div`
  grid-area: body;
  padding: 16px;
  overflow: auto;
  @media (max-width: ${({ theme }) => theme.verticalLayout}px) {
    padding: 16px 8px;
  }
`;

const ExpectedValueContainer = ({
  header,
  column,
  body,
}: {
  header: JSX.Element;
  column: JSX.Element;
  body: JSX.Element;
}) => (
  <Container>
    <Header>{header}</Header>
    <LeftColumn>{column}</LeftColumn>
    <Body>{body}</Body>
  </Container>
);

const SimulationAttributeDetail = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  padding: 16px 8px;
`;

const SimulationTitle = styled.div`
  font-weight: bold;
  font-size: 0.8em;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
`;

const SimulationValue = styled.div`
  color: #004358;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
  font-size: 4em;

  @media (max-width: ${({ theme }) => theme.verticalLayout}px) {
    font-size: 2em;
  }
`;

const SimulationDetailContainer = styled.div`
  @media (max-width: ${({ theme }) => theme.verticalLayout}px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
const successColor = "#BEDB39";
const failureColor = "#FD7400";

const SimulationDetails = ({
  probability,
  successes,
  failures,
  loss,
  reward,
}: {
  probability: number;
  successes: number;
  failures: number;
  loss: number;
  reward: number;
}) => {
  const n = successes + failures;
  const perEvent = probability * reward - (1 - probability) * loss;
  const total = perEvent * n;
  return (
    <SimulationDetailContainer>
      <SimulationAttributeDetail>
        <SimulationTitle>
          Total Amount Won - Expected{" "}
          {total.toLocaleString("en", {
            style: "currency",
            currency: "USD",
          })}
        </SimulationTitle>
        <SimulationValue>
          {(successes * reward - failures * loss).toLocaleString("en", {
            style: "currency",
            currency: "USD",
          })}
        </SimulationValue>
      </SimulationAttributeDetail>
      <SimulationAttributeDetail>
        <SimulationTitle>
          Per Event - Expected{" "}
          {perEvent.toLocaleString("en", {
            style: "currency",
            currency: "USD",
          })}
        </SimulationTitle>
        <SimulationValue>
          {((reward * successes - failures * loss) / n).toLocaleString("en", {
            style: "currency",
            currency: "USD",
          })}
        </SimulationValue>
      </SimulationAttributeDetail>
      <SimulationAttributeDetail>
        <SimulationTitle>
          Success Probability - Expected{" "}
          {probability.toLocaleString("en", {
            style: "percent",
          })}
        </SimulationTitle>
        <SimulationValue>
          {(successes / (failures + successes)).toLocaleString("en", {
            style: "percent",
          })}
        </SimulationValue>
      </SimulationAttributeDetail>
      <SimulationAttributeDetail>
        <SimulationTitle>
          Successful Events - Expected{" "}
          {Math.round(probability * (successes + failures))}
        </SimulationTitle>
        <SimulationValue>
          <span
            style={{
              textShadow: `-1px -1px 0 ${successColor}, 1px -1px 0 ${successColor}, -1px 1px 0 ${successColor}, 1px 1px 0 ${successColor}`,
            }}
          >
            {successes}
          </span>
        </SimulationValue>
      </SimulationAttributeDetail>
      <SimulationAttributeDetail>
        <SimulationTitle>
          Failed Events - Expected{" "}
          {Math.round((1 - probability) * (successes + failures))}
        </SimulationTitle>
        <SimulationValue>
          <span
            style={{
              textShadow: `-1px -1px 0 ${failureColor}, 1px -1px 0 ${failureColor}, -1px 1px 0 ${failureColor}, 1px 1px 0 ${failureColor}`,
            }}
          >
            {failures}
          </span>
        </SimulationValue>
      </SimulationAttributeDetail>
    </SimulationDetailContainer>
  );
};

const EventBlock = styled.div`
  width: 16px;
  height: 16px;
  margin: 4px;
  display: inline-block;
`;

const SimulationEvent = ({ event }: { event: boolean }) => {
  return (
    <EventBlock
      style={{
        backgroundColor: event ? successColor : failureColor,
      }}
    ></EventBlock>
  );
};

const SimulationEventView = ({ events }: { events: boolean[] }) => {
  return (
    <div>
      {events.map((event, index) => {
        return <SimulationEvent key={index} event={event} />;
      })}
    </div>
  );
};

const Title = styled.div`
  font-size: 3em;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0 8px;
`;

const ExpectedValue = () => {
  const [uiProbability, setProbability] = React.useState("50");
  const [uiReward, setReward] = React.useState("10");
  const [uiLoss, setLoss] = React.useState("10");
  const [events, setEvents] = React.useState([Math.random()]);
  const [frequency, setFrequency] = React.useState(500);
  const probability = React.useMemo(() => {
    return parseInt(uiProbability) / 100;
  }, [uiProbability]);

  React.useEffect(() => {
    const tick = setInterval(() => {
      if (events.length >= 5000 || frequency === 1000) return;
      setEvents((events) => [...events, Math.random()]);
    }, frequency);

    return () => clearInterval(tick);
  }, [setEvents, frequency, events]);

  const simulation = React.useMemo(() => {
    const results = events.map((event) => event < probability);
    return results.reduce(
      (memo, result) =>
        result
          ? { ...memo, successes: memo.successes + 1 }
          : { ...memo, failures: memo.failures + 1 },
      { successes: 0, failures: 0, results }
    );
  }, [events, probability]);

  const loss = React.useMemo(() => parseInt(uiLoss), [uiLoss]);
  const reward = React.useMemo(() => parseInt(uiReward), [uiReward]);

  const n = simulation.successes + simulation.failures;
  const perEvent = probability * reward - (1 - probability) * loss;
  const total = perEvent * n;

  return (
    <Context>
      <div id="app">
        <Head
          title={"Expected Value Simulator"}
          excerpt={
            "There is nothing to help the concept of expected value decision making to sink in like visual proof."
          }
        >
          <link
            href="https://fonts.googleapis.com/css?family=Inter&display=swap"
            rel="stylesheet"
          />
        </Head>
        <Global />
        <ExpectedValueContainer
          header={<Title>Expected Value</Title>}
          column={
            <>
              <SimulationAttributeDetail>
                <div>
                  <label>Simulation Speed</label>
                  <input
                    type="range"
                    step="100"
                    min="0"
                    max="1000"
                    value={1000 - frequency}
                    onChange={(e) => {
                      setFrequency(1000 - parseInt(e.target.value));
                    }}
                  />
                </div>
                <div>
                  <label>How likely is success?</label>
                  <input
                    type="number"
                    step="1"
                    min="0"
                    max="100"
                    value={uiProbability}
                    onChange={(e) => {
                      setProbability(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label>How much if you win?</label>
                  <input
                    type="number"
                    step="1"
                    min="0"
                    value={uiReward}
                    onChange={(e) => {
                      setReward(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label>How much do you pay if you lose?</label>
                  <input
                    type="number"
                    step="1"
                    min="0"
                    value={uiLoss}
                    onChange={(e) => {
                      setLoss(e.target.value);
                    }}
                  />
                </div>
                <div>
                  Let's make a bet, you win{" "}
                  {probability.toLocaleString("en", {
                    style: "percent",
                  })}{" "}
                  of the time. If you win, I'll pay you{" "}
                  {reward.toLocaleString("en", {
                    style: "currency",
                    currency: "USD",
                  })}{" "}
                  if you lose, you'll pay me{" "}
                  {loss.toLocaleString("en", {
                    style: "currency",
                    currency: "USD",
                  })}
                  . Expected Value you tells me per event I should expect{" "}
                  {perEvent.toLocaleString("en", {
                    style: "currency",
                    currency: "USD",
                  })}{" "}
                  and if we do this bet {n} times I should expect{" "}
                  {total.toLocaleString("en", {
                    style: "currency",
                    currency: "USD",
                  })}
                  .
                </div>
              </SimulationAttributeDetail>
              <SimulationDetails
                loss={loss}
                reward={reward}
                probability={probability}
                successes={simulation.successes}
                failures={simulation.failures}
              />
            </>
          }
          body={<SimulationEventView events={simulation.results} />}
        />
      </div>
    </Context>
  );
};

export default ExpectedValue;
